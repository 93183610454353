import React from "react";
import {Link} from "gatsby";
import Img from "gatsby-image";
import Tags from "./tags";

export default function PostPreview({post, showDate = true}) {
    const title = post.frontmatter.title || post.frontmatter.slug;
    let featuredImg = '';
    if (post.frontmatter.featuredImage) {
        let featuredImgFluid = post.frontmatter.featuredImage.childImageSharp.fluid;
        featuredImg = <Img fluid={featuredImgFluid} className='post-preview-img'/>;
    }

    let postDate = '';
    if (showDate) {
        postDate = (
            <small className="post-date">{post.frontmatter.date}</small>
        );
    }

    let description = '';
    if (post.frontmatter.description || post.excerpt) {
        description = (
            <p
                dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                }}
                itemProp="description"
                className="post-description"
            />
        );
    }

    let category = '';
    if (post.frontmatter.category) {
        category = (
            <span className="post-category">{post.frontmatter.category}</span>
        );
    }

    return (
        <article
            className="post-list-item"
            itemScope
            itemType="http://schema.org/Article"
        >
            {featuredImg}
            <div className='post-preview'>
                <h2>
                    <Link to={post.frontmatter.slug} itemProp="url">
                        <span itemProp="headline">{title}</span>
                    </Link>
                </h2>
                {postDate}
                {category}
                <Tags tags={post.frontmatter.tags}/>
                {description}
            </div>
        </article>
    )
}
